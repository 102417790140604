/*
 * @Author: Li
 * @Date: 2019-12-27 10:36:47
 * @Descripttion: 域名管理
 * @Param: 
 */
let dev =  window.location.host === 'desk.mayidianjing.com' || window.location.host === 'cdndesk.mayidianjing.com' || window.location.host === 'ant.mayidianjing.com' ? 'https://api.mayidianjing.com:800/' : 'https://api.mayidianjing.com:800/';

import cMethods from "@/util/cMethods.js";
console.log("配置文件api地址=", cMethods.Form_jsGetAPIPort())

if(cMethods.Form_jsGetAPIPort()) {
    let setApiUrl =  cMethods.Form_jsGetAPIPort()
    setApiUrl = setApiUrl.replace('api/', '')
    dev = setApiUrl
}

const baseConfig = {
    dev: dev,
    // dev: 'https://api.mayidianjing.com:800/',
    // url: 'http://newc.580tequan.com/',
    key: '10003', // 秘钥 16n:
    iv: '5D7AB40F-3E47-49B3-B74C-D261B69DA000',
    timeout: 10000, // 偏移量
}

export default baseConfig
