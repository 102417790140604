<template>
  <div class="auth-wrap">
    <!-- 实名认证弹窗 -->
    <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" :close-on-click-modal="false" width="432px"
      custom-class="auth-dialog" v-if="userInfo">
      <template slot="title">
        <div class="title" v-if="userInfo.IDCard">
          <img src="@/assets/img/auth.png" alt="" />
          已认证
        </div>
        <div class="title" v-else>实名认证</div>
      </template>

      <div v-if="userInfo.IDCard">
        <div class="auth-info">
          <label>真实姓名：</label><span>{{
            userInfo.RealName.replace(userInfo.RealName.substring(0, 1), "*")
          }}</span>
          <el-divider></el-divider>
          <label>身份证号码：</label><span>{{
            userInfo.IDCard.replace(
              userInfo.IDCard.substring(3, userInfo.IDCard.length - 3),
              "************"
            )
          }}</span>
        </div>
      </div>

      <div class="auth-form-box" v-else>
        <!-- <div class="success-box" v-if="isSuccess">
          <img src="@/assets/img/idCard.png" alt="" />
          <h1>恭喜你</h1>
          <h2>实名认证成功</h2>
        </div>  v-else -->
        <div>
          <p>
            根据国家法律规定及对未成年的保护，使用网络平台服务需要实名认证！
          </p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px">
            <el-form-item label="真实姓名：" prop="RealName">
              <el-input v-model.trim="ruleForm.RealName" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号码：" prop="IDCard">
              <el-input v-model.trim="ruleForm.IDCard" placeholder="请输入身份证号码"></el-input>
            </el-form-item>
          </el-form>

          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForm('ruleForm')" style="width: 100%" :loading="loading">
              {{ loading ? `${timer}秒后提交` : '提交实名' }}
            </el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { authentication } from "@/api/index";

export default {
  props: {
    updateVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["userInfo", "getIsAuth"]),
  },
  data() {
    var validateName = (rule, value, callback) => {
      if (value && value !== "") {
        let regEn = /^[\u4e00-\u9fa5]{2,4}$/;
        if (!regEn.test(value)) {
          callback(new Error("真实姓名填写有误"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validateIdCard = (rule, value, callback) => {
      if (value && value !== "") {
        let regEn = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!regEn.test(value)) {
          callback(new Error("身份证号填写有误"));
        } else {

          callback();
        }
      } else {
        callback();
      }
    };
    return {
      loading: false,
      dialogVisible: false,
      timer: 30,

      timeInterVal: null,
      ruleForm: {
        // RealName: "黄伊慧",
        // IDCard: "330323198203191841",
        RealName: "",
        IDCard: "",
      },
      isSuccess: false,
      rules: {
        RealName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
          // { validator: validateName, trigger: "blur" },
        ],
        IDCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          { validator: validateIdCard, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      this.$emit("changeShow", "false");
    },
    // 实名认证表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this._authentication();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async _authentication() {
      let params = {
        ConsumerId: this.userInfo.ConsumerId,
        RealName: this.ruleForm.RealName,
        IDCard: this.ruleForm.IDCard,
      };
      let res = await authentication(params);

      clearInterval(this.timeInterVal)
      this.timeInterVal = setInterval(() => {
        if (this.timer == 0) {
          this.loading = false;
          clearInterval(this.timeInterVal)
          this.timeInterVal = null;
          this.timer = 30;
        }
        this.timer--;
      }, 1000)

      if (res.StatusCode == 200) {
        this.$message({
          type: "success",
          message: "实名认证成功",
        });
        this.$store.dispatch("_GetUserInfo");
        this.dialogVisible = false;
      }
    },
  },
  watch: {
    // 监听 updateVisible 改变
    updateVisible(oldVal, newVal) {
      this.dialogVisible = this.updateVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-dialog {
  .title {
    font-size: 16px;
    font-weight: bold;
    @include font_col3();

    img {
      width: 24px;
      height: 25px;
      margin-right: 10px;
    }
  }

  .auth-info {
    font-size: 16px;

    label {
      width: 100px;
      display: inline-block;
      @include font_col3();
    }

    span {
      @include font_col9();
    }

    .el-divider--horizontal {
      margin: 20px 0;
    }
  }

  .auth-form-box {
    @include font_col3();

    p {
      line-height: 26px;
      margin-bottom: 20px;
      @include font_col6();
    }

    .success-box {
      line-height: 30px;
      text-align: center;

      h1 {
        margin-top: 10px;
        font-size: 26px;
        font-weight: 500;
      }

      h2 {
        margin: 10px 0 8px 0;
        font-size: 22px;
        font-weight: 500;
      }
    }
  }
}
</style>