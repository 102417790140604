import ArchiveDialog from './ArchiveDialog.vue';
import { Loading } from 'element-ui';

export default {
    install(Vue, options) {
        // 创建一个全局弹窗的Vue实例
        const ArchiveDialogConstructor = Vue.extend(ArchiveDialog);
        const instance = new ArchiveDialogConstructor({
            el: document.createElement('div'),
            propsData: options
        });

        // // 将弹窗挂载到body上
        document.body.appendChild(instance.$el);

        // 暴露全局方法
        Vue.prototype.showDialog = (message) => {
            instance.showArchiveDialog();
        };
        Vue.prototype.closeDialog = (message) => {
            instance.closeArchiveDialog();
        };
        // 全局刷新
        Vue.prototype.refresh = () => {
            if (window.TQ) {
                const loading = Loading.service({
                    lock: true,
                    text: "刷新中...",
                    spinner: "el-icon-loading",
                    customClass: "loading_tour",
                    background: "rgba(6, 6, 6, 0.7)",
                });

                TQ.isRouterOrder = false;
                TQ.$nextTick(function () {
                    // 刷新页面
                    TQ.$router.replace({ path: "/refresh" });
                    TQ.isRouterOrder = true;
                });
                if (sessionStorage.getItem("Phone")) {
                    TQ.$store.dispatch("_GetUserInfo");
                }
                setTimeout(() => {
                    loading.close();
                }, 300);
            }

        };
    }
};