/*
 * @Author: Li
 * @Date: 2019-12-29 16:31:38
 * @Descripttion: 蚂蚁特权api请求
 * @Param:
 */

import { get, post } from "@/util/axios";

let source = 3;

//发送手机验证码
export function GetPhoneCode(params) {
  return get("api/Consumer/GetPhoneCode", {
    phone: params.phone,
  });
}

//短信验证码登录
export function CodeLogin(params) {
  return get("api/Consumer/CodeLogin", {
    phone: params.phone,
    phoneCode: params.phoneCode,
  });
}

//用户信息
export function GetUserInfo(params) {
  return get("api/Consumer/GetUserInfo", {
    phone: params.phone,
  });
}

//获取网吧信息
export function GetNetbarInfo(params) {
  return get("api/Netbar/GetNetbarInfo", {
    netbarId: params.netbarId,
  });
}

//网吧是否为钻石版
export function GetSteamPower(params) {
  return get("api/SteamOrder/GetSteamPower", {
    netbarId: params.netbarId,
  });
}

//微信二维码
export function GetQRCode(params) {
  return get("api/Consumer/GetQRCode", {
    random: params.random,
  });
}

//微信登录验证
export function AjaxIsAuthorize(params) {
  return get("api/Consumer/AjaxIsAuthorize", {
    code: params.code,
  });
}

//轮播广告数据
export function GetNavigationList(params) {
  return get("api/Netbar/GetNavigationList", {
    type: params.type,
  });
}

//创建订单
export function CreateOrder(params) {
  return post("api/SteamOrder/CreateOrder", {
    netbarId: params.netbarId,
    phone: params.phone,
    gameId: params.gameId,
    gameName: params.gameName,
    count: 1,
  });
}

//订单列表
export function GetOrder(params) {
  return get("api/SteamOrder/GetOrder", {
    phone: params.phone,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex,
  });
}

//意见反馈
export function ProposalAdd(params) {
  return post("api/Proposal/ProposalAdd", {
    NetbarId: params.NetbarId,
    NetbarName: params.NetbarName,
    ConsumerId: params.ConsumerId,
    Type: params.Type,
    Content: params.Content,
    OrderNumber: params.OrderNumber,
    ValidateKey: params.ValidateKey,
    Code: params.Code,
  });
}

//获取平台、网吧用户和游戏数据
export function GetFalseData(params) {
  return get("api/Netbar/GetFalseData", {
    netbarId: params.netbarId,
  });
}

//手机号绑定
export function BindPhone(params) {
  return get("api/Consumer/BindPhone", {
    consumerId: params.consumerId,
    phone: params.phone,
    phoneCode: params.phoneCode,
  });
}

//获取游戏列表
export function GetGameList(params) {
  return get("api/SteamOrder/GetGameList", {
    source: source,
    keyword: params.keyword || "",
    pageSize: params.pageSize,
    pageIndex: params.pageIndex,
    labels: params.labels || "",
    platformId: params.platformId || "",

    // installed: "",
    // isArchive: "",
    // isPerfectArchive: "",
    // isNewGame
  });
}
//获取游戏列表
export function PostGameListNew(params) {
  return post("api/SteamOrder/PostGameList", params);
}

//获取游戏列表
export function GetGameListNew(params) {
  return get("api/SteamOrder/GetGameList", params);
}

//获取单个商品详情
export function GetGameInfo(params) {
  return get("api/SteamOrder/GetGameInfo", {
    gid: params.gid,
  });
}

//获取商品相关推荐
export function GetSimilarGameList(params) {
  return get("api/SteamOrder/GetSimilarGameList", {
    labels: params.labels,
    source: source,
    rows: params.rows || 8,
    sidx: params.sidx,
  });
}

//获取首页推荐游戏
export function GetHomePageGames(params) {
  return get("api/SteamOrder/GetHomePageGames", {
    source: source,
    type: params.type,
    navigationType: params.navigationType,
  });
}

// 获取登录过的游戏记录
export function GetGameAccountList(params) {
  return get("api/SteamOrder/GetGameAccountList", {
    VipID: params.VipID,
    ProductID: params.ProductID,
  });
}

// 获取添加上号
export function AddGameLoginAccount(params) {
  return get("api/SteamOrder/AddGameLoginAccount", {
    VipID: params.VipID,
    ProductID: params.ProductID,
    AccountID: params.AccountID,
    Machinecode: params.Machinecode,
    App: 5, // 好友助手：5
    NetbarId: params.NetbarId,
  });
}

// 获取登录账号
export function GetGameAccountLogin(params) {
  return get("api/SteamOrder/GetGameAccount", {
    VipID: params.VipID,
    Tel: params.Tel,
    ProductID: params.ProductID,
    Machinecode: params.Machinecode,
    App: 3, // 蚂蚁特权：3
    // IsVerifyVip: params.IsVerifyVip,
    NetbarId: params.netbarId,
    NetbarName: params.netbarName,
    IsNotVerifyIDCard: params.isNotVerifyIDCard,
    AccountId: params.accountId || "",
    GameAccount: params.gameAccount || "",
    IsSpecify: params.gameAccount ? 1 : 0
  });
}

// 获取会员套餐
export function GetMemberPackage() {
  return get("api/PurchaseOrder/GetMemberPackage", {});
}

// 下单获取二维码
export function CrateOrder(params) {
  return post("api/PurchaseOrder/CrateOrder", {
    ConsumerId: params.ConsumerId,
    MemberPackageId: params.MemberPackageId,
    NetbarId: params.NetbarId,
  });
}

// 查看订单状态
export function GetOrderPayState(params) {
  return get("api/PurchaseOrder/GetOrderPayState", {
    orderId: params.orderId,
  });
}

// 我的订单
export function GetOrderList(params) {
  return get("api/PurchaseOrder/GetOrderList", {
    consumerId: params.consumerId,
    pageIndex: params.pageIndex,
    pageSize: params.pageSize,
  });
}

// 获取钻石网吧当前可用名额
export function GetNetbarOnlineNumber(params) {
  return get("api/SteamOrder/GetNetbarOnlineNumber", {
    netbarId: params.netbarId,
    phone: params.phone,
    app: 3,
  });
}

// 最近游戏
export function GetFinalVipGameBysource(params) {
  return get("api/SteamOrder/GetFinalVipGameBysource", {
    vipId: params.vipId,
    source: source,
  });
}

// 最近游戏列表
export function GetHistorGameProductList(params) {
  return get("api/SteamOrder/GetHistorGameProductList", {
    vipId: params.vipId,
    source: source,
    rows: params.rows,
    page: params.page,
    isPager: true,
    sord: "desc",
    sidx: "addTime",
  });
}

// 获取支付开关
export function GetPaymentSwitch() {
  return get("api/Shortcut/GetPaymentSwitch", {});
}

// 首页热门搜索
export function GetGameKeyword() {
  return get("api/SteamOrder/GetGameKeyword", {
    source: source,
  });
}

// 获取绝地排位号开关
export function GetGameSwitch() {
  return get("api/Shortcut/GetGameSwitch", {});
}

// 获取公告
export function GetTop1Msg() {
  return get("api/Proposal/GetTop1Msg", {});
}

// 获取游戏存档
export function GetGameArchive(params) {
  return get("api/SteamOrder/GetGameArchive", {
    consumerId: params.consumerId,
    gameId: params.gameId,
    source: source,
  });
}

// 获取游戏存档
export function GetGameSubscribe(params) {
  return get("api/SteamOrder/GetGameSubscribe", {
    consumerId: params.consumerId,
    source: source,
  });
}

// 获取游戏存档
export function GameSubscribe(params) {
  return post("api/SteamOrder/GameSubscribe", {
    gameId: params.gameId,
    gameName: params.gameName,
    phone: params.phone,
    consumerId: params.consumerId,
    netbarId: params.netbarId,
    netbarName: params.netbarName,
  });
}

// 盲盒开奖结果
export function consumerLuckDraw(params) {
  return post(
    "api/Consumer/ConsumerLuckDraw?consumerId=" +
    params.consumerId +
    "&prizeName=" +
    params.prizeName,
    {
      consumerId: params.consumerId,
      prizeName: params.prizeName,
    }
  );
}

// 盲盒开奖中奖名单
export function getConsumerLuckDrawLog(params) {
  return get("api/Consumer/GetConsumerLuckDrawLog", {
    random: params.random,
    phone: params.phone || "",
  });
}

// 实名认证提交
export function authentication(params) {
  return post("api/Consumer/Authentication", {
    ConsumerId: params.ConsumerId,
    RealName: params.RealName,
    IDCard: params.IDCard,
  });
}

// 获取实名认证开关
export function getIsNotVerificationIDCard() {
  return get("api/NetbarState/GetIsNotVerificationIDCard", {});
}

// 获取高端游戏列表
export function GetVerifyGame() {
  return get("api/SteamOrder/GetVerifyGame", {});
}

// 获取某些游戏时长
export function GetConsumerGameTimeByHaoYou(params) {
  return get("api/Consumer/GetConsumerGameTimeByHaoYou", {
    phone: params.phone,
  });
}

// 获取精品账号
export function GetGameProducts(params) {
  return get("api/SteamOrder/GetGameProducts", params);
}

// 获取综合排序
export function GetAccounts(params) {
  return get("api/SteamOrder/GetAccounts", params);
}

// 用户设置主题颜色
export function getSetSkin(params) {
  return get("/api/Consumer/SetSkin", {
    consumerId: params.consumerId,
    value: params.value,
  });
}

// 切换游戏
export function GetCurrentGameLogin(params) {
  return get("/api/SteamOrder/GetGameLoginLogByState", {
    userId: params.userId,
    netbarId: params.netbarId,
    source: source,
  });
}

// 获取修改器开关
export function GetIsWeBuff(params) {
  return get("/api/SteamOrder/GetIsWeBuff", {
    steamGameId: params.steamGameId,
  });
}

// 获取免费玩游戏标签
export function GetGameLabels() {
  return get("/api/SteamOrder/GetGameLabels", {
    source: source,
  });
}

// 收藏
export function UserCollection(params) {
  return get("/api/Consumer/UserCollection", {
    consumerId: params.consumerId,
    gameId: params.gameId,
    isCollection: params.isCollection,
  });
}

// 详情页收藏状态
export function GetIsCollectionGame(params) {
  return get("/api/Consumer/GetIsCollectionGame", {
    consumerId: params.consumerId,
    gameId: params.gameId,
  });
}

// 获取我的存档
export function GetUserGameArchive(params) {
  return get("/api/SteamOrder/GetUserGameArchive", {
    source: source,
    consumerId: params.consumerId,
    gameName: params.gameName,
  });
}

// 删除个人存档
export function DelteGameArchive(params) {
  return get("/api/SteamOrder/DelteGameArchive", {
    archiveId: params.archiveId,
  });
}

// 获取离线账号
export function GetOfflineAccount(params) {
  return get("/api/SteamOrder/GetOfflineAccount", {
    vipId: params.vipId,
    gameId: params.gameId,
    app: 3,
    machinecode: params.machinecode,
    netbarId: params.netbarId,
  });
}

// 获取用户封禁状态
export function GetProposalList(params) {
  return get("/api/Proposal/GetProposalList", {
    consumerId: params.consumerId,
  });
}

// 获取网吧游戏菜单
export function GetGames(params) {
  return post("/api/SteamOrder/GetGameMenu", {
    netbarId: params.netbarId,
    source: 3,
    steamGameId: params.steamGameId,
    gameNmae: "",
    gameAliasName: "",
    gameLanguage: "",
    pageIndex: params.pageIndex,
    pageSize: 10000,
  });
}

// 获取赛事活动游戏图片
export function GetActivityGames() {
  return get("api/Activity/GetActivityGames", {});
}

//获取赛事活动列表
export function GetActivity2(params) {
  return get("api/Activity/GetActivity2", {
    netbarId: params.netbarId,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex,
    state: params.state,
    userId: params.userId,
    gameName: params.gameName,
  });
}

//赛事活动报名
export function SaveActivity2Participant(params) {
  return post("api/Activity/SaveActivity2Participant", {
    // Id: params.Id,
    ActivityId: params.ActivityId,
    UserId: params.UserId,
    Phone: params.Phone,
    NetbarId: params.NetbarId,
    NetbarName: params.NetbarName,
    // State: params.State,
    // Ranking: params.Ranking,
    // CreateTime: params.CreateTime,
  });
}

// 获取赛事规则
export function GetActivity2Participants(params) {
  return get("api/Activity/GetActivity2Participants", {
    activityId: params.activityId,
    userId: params.userId,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex,
  });
}

// 获取用户福利获取记录
export function GetUserWelfareLogs(params) {
  return get("api/Lottery/GetUserWelfareLogs", {
    userId: params.userId,
    state: params.state,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex,
  });
}

// 获取用户福利获取记录
export function ReceiveWelfare(params) {
  return post("api/Lottery/ReceiveWelfare", {
    Phone: params.Phone,
    Number: params.Number,
  });
}

// 激活福利
export function ActivationWelfare(params) {
  return get("api/Lottery/ActivationWelfare", {
    welfareLogId: params.welfareLogId,
  });
}

// 用户注销
export function LogOff(params) {
  return get("api/Consumer/LogOff", {
    consumerId: params.consumerId,
    phone: params.phone,
    phoneCode: params.phoneCode,
  });
}

// 获取广告
export function GetList(params) {
  return get("api/Advertising/GetList", {
    adType: "客户端页面广告",
  });
}

// 获取积分商城任务
export function GetIntegralTasks(params) {
  return get("api/TaskRelevant/GetIntegralTasks", {
    consumerId: params.consumerId,
    isShow: 1
  });
}

// 获取积分商城任务
export function CompleteTask(params) {
  return post("api/TaskRelevant/CompleteTask", {
    UserId: params.UserId,
    NetbarId: params.NetbarId,
    Type: params.Type
  });
}

// 获取7天签到数据
export function GetCheckInLog(params) {
  return get("api/TaskRelevant/GetCheckInLog", {
    consumerId: params.consumerId,
  });
}

// 游戏时长兑换积分
export function ExchangeIntegral(params) {
  return get("api/TaskRelevant/ExchangeIntegral", {
    phone: params.phone,
  });
}

// 三要素认证次数
export function GetUserVerifyCount(params) {
  return get("api/Consumer/GetUserVerifyCount", {
    consumerId: params.consumerId,
  });
}

// 获取用户当天游戏时长
export function GetGameTime(params) {
  return get("api/SteamOrder/GetGameTime", {
    phone: params.phone,
  });
}