<template>
    <!-- 游戏存档名 -->
    <el-dialog title="保存存档" :visible.sync="showGameNameDialog" width="400px" custom-class="gameNameDialog"
        :close-on-click-modal="false" :before-close="closeArchiveDialog">
        <el-form :inline="true" :model="form">
            <el-form-item label="存档名称：">
                <el-input v-model.trim="form.gameName" placeholder="请输入存档名称"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeArchiveDialog">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import cMethods from "@/util/cMethods.js";

export default {
    data() {
        return {
            showGameNameDialog: false,
            form: {
                gameName: ''
            }
        }
    },
    methods: {
        // 开启游戏存档
        showArchiveDialog() {
            this.form.gameName = "";
            this.showGameNameDialog = true;
        },
        // 关闭游戏存档
        closeArchiveDialog() {
            this.showGameNameDialog = false;
            cMethods.FormJsGetArchiveCustomFile(0, "");
            console.log("关闭");
        },
        // 保存存档
        submit() {
            if (!this.form.gameName) {
                this.$message({
                    type: "error",
                    message: "请输入存档名称",
                });
                return false;
            }
            cMethods.FormJsGetArchiveCustomFile(1, this.form.gameName);
            this.showGameNameDialog = false;
            console.log("确定");
        },
    }
}
</script>

<style lang="scss" scoped>
.gameNameDialog {
    .el-dialog__body {
        .el-input {
            width: 230px;
        }
    }

    .el-button--default:focus,
    .el-button--default:hover {
        color: #ff3636;
        border-color: #ff3636;
        background-color: transparent;
    }

    .el-dialog__headerbtn:focus .el-dialog__close,
    .el-dialog__headerbtn:hover .el-dialog__close {
        color: #ff3636;
    }
}
</style>