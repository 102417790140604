import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  Message
} from 'element-ui';

Vue.use(VueRouter)

// 添加这下面一段代码，就可以解决报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

const routes = [
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Index.vue') //首页
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue') //免费玩
  },
  // {
  //   path: '/gameList',
  //   name: 'gameList',
  //   component: () => import('../views/GameList.vue') //游戏列表
  // },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import('../views/UserCenter/Index.vue'), //用户中心
    redirect: '/userCenter/history',
    children: [
      {
        path: '/userCenter/history',
        component: () => import('../views/UserCenter/History/Index.vue'), // 最近玩的游戏 
        name: 'history'
      },
      {
        path: '/userCenter/myCollection',
        component: () => import('../views/UserCenter/MyCollection/Index.vue'), // 我的收藏 
        name: 'myCollection'
      },
      {
        path: '/userCenter/myItems',
        component: () => import('../views/UserCenter/MyItems/Index.vue'), // 我的存档 
        name: 'myItems'
      },
      {
        path: '/userCenter/order',
        component: () => import('../views/UserCenter/Order/Index.vue'), // 会员订单 
        name: 'order'
      },
      {
        path: '/userCenter/exchange',
        component: () => import('../views/UserCenter/Exchange/Index.vue'), // 兑换卡券 
        name: 'exchange'
      },
      {
        path: '/userCenter/integral',
        component: () => import('../views/UserCenter/Integral/Index.vue'), // 积分订单 
        name: 'integral'
      },
    ]
  },
  {
    path: '/points',
    name: 'points',
    component: () => import('../views/Points/Index.vue'), // 积分商城
  },
  {
    path: '/signin',
    component: () => import('../views/Points/SignIn/Index.vue'), // 积分签到
    name: 'signin'
  },
  // {
  //   path: '/pointsList',
  //   component: () => import('../views/Points/PointsList/Index.vue'), // 积分列表
  //   name: 'pointsList'
  // },
  // {
  //   path: '/pointsDetail/:id',
  //   name: 'pointsDetail',
  //   component: () => import('../views/Points/Detail/Index.vue'), // 积分商城详情页
  // },
  // {
  //   path: '/integralDetail/:id',
  //   name: 'integralDetail',
  //   component: () => import('../views/Points/IntegralDetail/Index.vue'), // 积分兑换订单详情页
  // },
  {
    path: '/members',
    name: 'members',
    component: () => import('../views/Members.vue') //会员
  },
  // {
  //   path: '/order',
  //   name: 'order',
  //   component: () => import('../views/Order.vue') //我的订单
  // },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/FeedBack.vue') //意见反馈
  },
  // {
  //   path: '/historicalFeedback',
  //   name: 'historicalFeedback',
  //   component: () => import('../views/HistoricalFeedback.vue') //历史反馈
  // },
  {
    path: '/gamedetail/:id',
    name: 'gamedetail',
    component: () => import('../views/GameDetail.vue') //游戏详情
  },
  // {
  //   path: '/history',
  //   name: 'history',
  //   component: () => import('../views/History.vue') //历史游戏列表
  // },
  // {
  //   path: '/thb',
  //   name: 'thb',
  //   component: () => import('../views/THB/Index.vue') //买号玩
  // },
  {
    path: '/link',
    name: 'link',
    component: () => import('../views/THB/Link.vue') //公共跳转页
  },
  {
    path: '/playWith',
    name: 'playWith',
    component: () => import('../views/PlayWith/Index.vue') //陪玩
  },
  {
    path: '/bulletinBoard',
    name: 'bulletinBoard',
    component: () => import('../views/BulletinBoard.vue') //新游预告榜
  },
  {
    path: '/blindBox',
    name: 'blindBox',
    component: () => import('../views/BlindBox.vue') //盲盒
  },
  {
    path: '/boutique',
    name: 'boutique',
    component: () => import('../views/Boutique.vue') //陪玩
  },
  {
    path: '/boutiqueDetail',
    name: 'boutiqueDetail',
    component: () => import('../views/BoutiqueDetail.vue') //陪玩
  },
  {
    path: '/LiveRoom',
    name: 'LiveRoom',
    component: () => import('../views/LiveRoom.vue') //陪玩
  },
  {
    path: '/perfectArchiving',
    name: 'perfectArchiving',
    component: () => import('../views/PerfectArchiving.vue') //完美存档
  },
  // {
  //   path: '/myItems',
  //   name: 'myItems',
  //   component: () => import('../views/MyItems.vue') //我的存档
  // },
  // {
  //   path: '/myCollection',
  //   name: 'myCollection',
  //   component: () => import('../views/MyCollection.vue') //我的收藏
  // },
  {
    path: '/refresh',
    name: 'refresh',
    component: () => import('../views/Refresh.vue') //刷新页面
  },
  {
    path: '/steam',
    name: 'steam',
    component: () => import('../views/Steam.vue') //steam加速器
  },
  {
    path: '/eSports',
    name: 'eSports',
    component: () => import('../views/ESports.vue') //steam加速器
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/Menu.vue') //游戏菜单
  },
  {
    path: '/hspl',
    name: 'hspl',
    component: () => import('../views/NewGame/hspl.vue') //幻兽帕鲁
  },
  {
    path: '/weeklyFreeGame',
    name: 'weeklyFreeGame',
    component: () => import('../views/WeeklyFreeGames.vue') //周免游戏
  },
  {
    path: '/',
    redirect: '/index'
  },
]



const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) { //如果未匹配到路由
    from.name ? next({
      name: from.name
    }) : next('/'); //如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由
  } else {
    next();
  }
});

export default router