<template>
    <el-dialog :visible.sync="dialogVisible" :title="title" :width="width" :fullscreen="fullscreen" :top="top" :modal="true"
        :modal-append-to-body="true" :append-to-body="false" :lock-scroll="true" custom-class="globle-dialog"
        :close-on-click-modal="false" :close-on-press-escape="true" :show-close="true" :center="center"
        :destroy-on-close="false">
        <slot />
        <span slot="footer" class="dialog-footer" v-if="hasFooter">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        //     show: {
        //         type: Boolean,
        //         default: false
        //     },
        title: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: '50%'
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        top: {
            type: String,
            default: '15vh'
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        center: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        setDialogVisible(visible) {
            this.dialogVisible = visible;
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .globle-dialog {
    .el-dialog__body {
        max-height: 400px;
        overflow: auto;

        h3 {
            font-weight: bold;
            margin: 15px 0;
        }

        p {
            line-height: 30px;
            // text-indent: 2em;
        }
    }
}
</style>