// C++方法
const cMethods = {
  // 获取网吧名
  GetNetBarName: () => {
    if (typeof Extern_Form_GetNetBarName === "function") {
      let res = Extern_Form_GetNetBarName();
      return res;
    }
  },
  // 获取网吧ID
  GetNetBarID: () => {
    if (typeof Extern_Form_GetNetBarID === "function") {
      let res = Extern_Form_GetNetBarID();
      return res;
    }
  },
  // 打开新页面
  OpenIeUrl: (url) => {
    if (typeof Extern_Form_open_ie_url === "function") {
      Extern_Form_open_ie_url(url);
    }
  },
  // 退出登录
  UserLoginOut: () => {
    if (typeof Extern_Form_UserLoginOut === "function") {
      Extern_Form_UserLoginOut();
    }
  },
  // 设置用户ID
  SetUserID: (ConsumerId) => {
    if (typeof Extern_Form_SetUserID === "function") {
      Extern_Form_SetUserID(ConsumerId);
    }
  },
  // 一键上号
  AutoSSQ: (Code, GameId, GameName) => {
    if (typeof Extern_Form_open_AutoSSQ === "function") {
      Extern_Form_open_AutoSSQ(Code, GameId, GameName);
    }
  },
  // 启动游戏加速器
  Open_GGBooster: () => {
    if (typeof Extern_Form_open_ggbooster === "function") {
      Extern_Form_open_ggbooster();
    }
  },
  // 最小化
  MiNiMize: () => {
    if (typeof Extern_Form_minimize === "function") {
      Extern_Form_minimize();
    }
  },
  // 执意退出
  FormExit: () => {
    if (typeof Extern_Form_exit === "function") {
      Extern_Form_exit();
    }
  },
  // 鼠标拖动
  FormCaptionDown: () => {
    if (typeof Extern_Form_caption_click === "function") {
      Extern_Form_caption_click("down");
    }
  },
  // 鼠标松开
  FormCaptionUp: () => {
    if (typeof Extern_Form_caption_click === "function") {
      Extern_Form_caption_click("up");
    }
  },
  // 打开GG租号
  FormOpenGG: () => {
    if (typeof Extern_Form_open_GG === "function") {
      Extern_Form_open_GG();
    }
  },
  // 修复存档
  FormRepairArchive: () => {
    if (typeof Extern_Form_RepairArchive === "function") {
      Extern_Form_RepairArchive();
    }
  },
  // 登录游戏
  FormStartGameForHaoYou: (
    vipID,
    GameAccount,
    Gamepwd,
    GameName,
    GameID,
    IsImg,
    WGID,
    OrderID,
    Answer,
    Problem,
    bScanPlugIn,
    bLoadDriver,
    AccountBancheck,
    ArchiveURL,
    currentsteamgameid,
    EnglishName,
    SteamAccountId,
    Explanation,
    IsGameSave,
    Email,
    Emailpwd,
    ArchiveType,
    archive = {},
    gameProcessName
  ) => {
    // console.log("str", str);
    // console.log('gameProcessName', gameProcessName);
    // console.log('DwUrl', archive.ArchiveLooalPath);
    // console.log('GameId', archive.GameId);
    // console.log('LocalArchivePath', archive.ArchiveLooalPath);

    // console.log('ArchiveType', ArchiveType)
    // console.log('archive', archive)
    // console.log('gameProcessName', gameProcessName)
    if (typeof Extern_Form_jsComInterface === "function") {
      Extern_Form_jsComInterface(
        JSON.stringify({
          funName: "StartGameForHaoYou",
          params: {
            appCode: "C9F590B12B87483DABFE0C03357E6854",
            appRelativePath: "shanghao\\",
            appVersion: "",
            moduleName: "business.dll",
            showUpdateWnd: false,
            updateType: 0,
            zoneName: "",
            currentsteamgameid: currentsteamgameid,
            account: GameAccount,
            answer: Answer,
            bLoadDriver: bLoadDriver,
            bPostScreenShot: IsImg,
            bScanPlugIn: bScanPlugIn,
            gameClassQName: "",
            gameid: WGID,
            gameName: GameName,
            IsMailVerify: false,
            MailHost: "",
            MailPort: 0,
            orderID: OrderID,
            password: Gamepwd,
            problem: Problem,
            ProductID: "",
            secondAccount: "",
            secondPwd: "",
            serverName: "",
            userId: vipID + "",
            gameIdForGameSave: GameID,
            accountbancheck: AccountBancheck,
            ArchiveURL: ArchiveURL,
            GameDirectory: EnglishName,
            SteamAccountID: SteamAccountId,
            Content: Explanation,
            IsGameSave: IsGameSave,
            Email: Email,
            Emailpwd: Emailpwd,
            gameProcessName: gameProcessName || "", // 进程字段
            ArchiveType: ArchiveType || 0,
            ArchiveData: {
              DwUrl: (archive && archive.ArchiveLooalPath) || "",
              GameId: (archive && archive.GameId) || "",
              ArchiveName: (archive && archive.ArchiveName) || "",
              // LocalArchivePath: archive && archive.ArchiveLooalPath || ""
            },
          },
        }),
        GameID,
        GameName
      );
    }
  },
  // 加速器
  ExternFormBoost: () => {
    if (typeof Extern_Form_Boost === "function") {
      Extern_Form_Boost(
        JSON.stringify({
          boostid: "859E12C39A3041F788DEA9760B99ECD2",
          pasth: "Accelerator\\\\hy\\\\",
          exename: "booster.exe",
        })
      );
    }
  },
  // 获取机器码
  FormGetMachineCode: () => {
    if (typeof Extern_Form_jsGetMachineCode === "function") {
      let res = Extern_Form_jsGetMachineCode();
      return res;
    }
  },
  // 新增获取网吧ID
  Extern_Form_jsComInterface_GetNetbarId: () => {
    if (typeof Extern_Form_jsComInterface === "function") {
      let res = Extern_Form_jsComInterface(
        JSON.stringify({
          funName: "GetNetbarId",
          params: null,
        })
      );
      return res;
    }
  },
  // 新增获取机器码
  Extern_Form_jsComInterface_GetMachineCode: () => {
    if (typeof Extern_Form_jsComInterface === "function") {
      let res = Extern_Form_jsComInterface(
        JSON.stringify({
          funName: "GetMachineCode",
          params: null,
        })
      );
      return res;
    }
  },
  // 日志输出
  Extern_Form_WriteHtmlLog: (log) => {
    if (typeof Extern_Form_jsComInterface === "function") {
      Extern_Form_jsComInterface(
        JSON.stringify({
          funName: "WriteHtmlLog",
          params: {
            log: log,
          },
        })
      );
    }
  },

  // 保存存档游戏
  FormJsGetArchiveCustomFile: (num, text) => {
    if (typeof Extern_Form_jsGetArchiveCustomFile === "function") {
      Extern_Form_jsGetArchiveCustomFile(num, text);
    }
  },

  // 是否为网吧环境
  FormIfNetBarSystem: () => {
    if (typeof Extern_Form_IfNetBarSystem === "function") {
      let res = Extern_Form_IfNetBarSystem();
      return res;
    }
  },

  // 阿里云截图上传
  FormAliScreenShot: (phone) => {
    if (typeof Extern_Form_AliScreenShot === "function") {
      Extern_Form_AliScreenShot(phone);
    }
  },

  // 重启游戏
  FormJsStartGameForPlatform: () => {
    if (typeof Extern_Form_jsStartGameForPlatform === "function") {
      Extern_Form_jsStartGameForPlatform();
    }
  },

  // 游戏目录
  FormJsGetUserGameName: (dirName, steamId) => {
    if (typeof Extern_Form_GetUserGameName === "function") {
      Extern_Form_GetUserGameName(dirName, steamId);
    }
  },

  // 游戏是否安装
  FormGetFindGameResult: () => {
    if (typeof Extern_Form_GetFindGameResult === "function") {
      let res = Extern_Form_GetFindGameResult();
      return res;
    }
  },

  // 启动修改器
  FormNetBarWeBuff: (steamId, url) => {
    if (typeof Extern_Form_NetBarWeBuff === "function") {
      let res = Extern_Form_NetBarWeBuff(steamId, url);
      return res;
    }
  },
  // 下载存档
  JsDownFileAndOpenDir: (url) => {
    if (typeof Extern_Form_jsDownFileAndOpenDir === "function") {
      Extern_Form_jsDownFileAndOpenDir(url);
    }
  },
  // 下载并替换存档
  JsDownSaveAndReplace: (gameId, url) => {
    if (typeof Extern_Form_jsDownSaveAndReplace === "function") {
      Extern_Form_jsDownSaveAndReplace(gameId, url);
    }
  },
  // 离线模式启动
  StartSteamOffline: (userId, mdStr, gameId, url) => {
    if (typeof Extern_Form_StartSteamOffline === "function") {
      console.log("离线模式参数", userId, mdStr, gameId, url);
      Extern_Form_StartSteamOffline(userId, mdStr, gameId, url);
    }
  },
  // 关闭steam
  JsExtern_Form_CloseSteam: () => {
    if (typeof Extern_Form_CloseSteam === "function") {
      Extern_Form_CloseSteam();
    }
  },
  // 检测游戏进程是否运行(放弃)
  // Form_jsIfGameRunForTQ: (gameId) => {
  //     if (typeof Extern_Form_jsIfGameRunForTQ === "function") {
  //         let res = Extern_Form_jsIfGameRunForTQ(gameId);
  //         return res;
  //     }
  // },

  // 上传备份存档
  Form_jsUploadArchiveForTQ: (userId, gameId, steamId) => {
    if (typeof Extern_Form_jsUploadArchiveForTQ === "function") {
      userId = userId + "";
      if (typeof gameId == "string" && gameId.indexOf("GM") > -1) {
        gameId = gameId.replace("GM", "");
      }
      console.log("上传存档参数...", userId, gameId, steamId);
      Extern_Form_jsUploadArchiveForTQ(userId, gameId, steamId);
    }
  },
  // 下载备份存档
  Form_jsDownSaveForTQ: (gameId, url) => {
    console.log("下载存档参数...", gameId, url);
    if (typeof Extern_Form_jsDownSaveForTQ === "function") {
      Extern_Form_jsDownSaveForTQ(gameId, url);
    }
  },
  // 获取加速器节点
  Form_jsGetSteamLineListSaibo: () => {
    if (typeof Extern_Form_jsGetSteamLineListSaibo === "function") {
      let res = Extern_Form_jsGetSteamLineListSaibo();
      return res;
    }
  },
  // 获取加速器状态
  Form_DetectAcceleratorStatus: () => {
    if (typeof Extern_Form_DetectAcceleratorStatus === "function") {
      let res = Extern_Form_DetectAcceleratorStatus();
      return res;
    }
  },
  // 区分当前系统win7还是win10
  Form_jsjsGetOsType: () => {
    if (typeof Extern_Form_jsjsGetOsType === "function") {
      let res = Extern_Form_jsjsGetOsType();
      return res;
    }
  },
  // 关闭加速器
  Form_jsStopAccelerator_1: () => {
    if (typeof Extern_Form_jsStopAccelerator_1 === "function") {
      Extern_Form_jsStopAccelerator_1();
    }
  },
  // 关闭加速器
  Form_jsStopSteamAccSaibo: () => {
    if (typeof Extern_Form_jsStopSteamAccSaibo === "function") {
      let res = Extern_Form_jsStopSteamAccSaibo();
      return res;
    }
  },
  // 开启加速器
  Form_jsAccelerator_1: (state, node) => {
    if (typeof Extern_Form_jsAccelerator_1 === "function") {
      Extern_Form_jsAccelerator_1(state, node);
    }
  },
  // 脚本注入
  Form_Accelerator: (ipStr, conf, urlStr, urlStr2, num) => {
    if (typeof Extern_Form_Accelerator === "function") {
      Extern_Form_Accelerator(ipStr, conf, urlStr, urlStr2, num);
    }
  },
  // 获取网吧安装steamid
  Form_jsGetLocalSsteamInstalledGames: (steamid) => {
    if (typeof Extern_Form_jsGetLocalSsteamInstalledGames === "function") {
      let res = Extern_Form_jsGetLocalSsteamInstalledGames(steamid);
      return res;
    }
  },
  // steam登录
  OpenOrgSteamLogin: (steamid) => {
    if (typeof Extern_Form_OpenOrgSteamLogin === "function") {
      return Extern_Form_OpenOrgSteamLogin(steamid);
    }
  },
  // 短时间内多次上号
  Form_GetShanghaoState: (gameName) => {
    if (typeof Extern_Form_GetShanghaoState === "function") {
      let res = Extern_Form_GetShanghaoState(gameName);
      return res;
    }
  },
  // 获取用户本地所有存档
  Form_jsGetLocalArchive: () => {
    if (typeof Extern_Form_jsGetLocalArchive === "function") {
      let res = Extern_Form_jsGetLocalArchive();
      return res;
    }
  },

  // 加速器状态   1：打开 2：关闭 3：是否打开  返回1:开启 0:没开启
  Form_jsControlsNginxAcce: (i) => {
    console.log("加速器状态i=", i);
    if (typeof Extern_Form_jsControlsNginxAcce === "function") {
      let res = Extern_Form_jsControlsNginxAcce(i);
      return res;
    }
  },
  // 客户端数据存储 type: 1读 2写  data:数据
  Form_jsControlsLocalWebData: (type, data) => {
    if (typeof Extern_Form_jsControlsLocalWebData === "function") {
      let res = Extern_Form_jsControlsLocalWebData(type, data);
      return res;
    }
  },
  // 截图
  Form_jsCaptureScreenAndCopyToClipboard: (type, data) => {
    if (typeof Extern_Form_jsCaptureScreenAndCopyToClipboard === "function") {
      let res = Extern_Form_jsCaptureScreenAndCopyToClipboard();
      return res;
    }
  },
  // 激活Steam
  Form_jsShowSteam: () => {
    if (typeof Extern_Form_jsShowSteam === "function") {
      Extern_Form_jsShowSteam();
    }
  },
  // api接口地址设置
  Form_jsGetAPIPort: (type, data) => {
    if (typeof Extern_Form_jsGetAPIPort === "function") {
      let res = Extern_Form_jsGetAPIPort();
      return res;
    }
  },
};

export default cMethods;
