import Dialog from './globle/Dialog.vue'
import ArchiveDialog from './globle/ArchiveDialog.vue'

const allGlobleComponent = { Dialog, ArchiveDialog }

export default {
    install(vue) {
        Object.keys(allGlobleComponent).forEach(key=>{
            vue.component(key, allGlobleComponent[key])
        })
    }
}