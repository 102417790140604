<template>
  <div class="menu-wrapper">
    <div class="avatar-box">
      <div v-if="userInfo">
        <router-link to="/members">
          <img class="crown" v-if="userInfo.IsNotMember" src="@/assets/images/members/crown.png" alt />
          <img class="crown" v-else src="@/assets/images/members/crown-1.png" alt />
        </router-link>

        <div class="header-box">
          <img class="avatar" :src="userInfo.HeadImgUrl
            ? userInfo.HeadImgUrl
            : require('@/assets/img/avatar.png')
            " alt />
          <img v-if="userInfo.IDCard" src="@/assets/img/isauth.png" class="auth-icon" @click="isAuthShowDialog = true" />
          <img v-else src="@/assets/img/noauth.png" class="auth-icon" @click="isAuthShowDialog = true" />
        </div>

        <p class="user-name">
          <a href="javascript:;">{{ userInfo.NickName }}</a>
        </p>
        <p class="user-phone" @click="changePhone">
          <a href="javascript:;">
            {{ userInfo.Phone }}
          </a>
          <el-button>{{ userInfo.Phone ? '换绑' : '绑定' }}</el-button>
        </p>
      </div>

      <div v-else>
        <div class="header-box">
          <img class="avatar" src="@/assets/img/avatar.png" @click="pleaseLogin" alt />
        </div>
        <p class="user-name" @click="pleaseLogin">
          <a href="javascript:;">请登录</a>
        </p>
      </div>

      <!-- <div v-if="!isLogin"></div>

      <div v-else></div> -->
    </div>

    <!-- <el-menu class="el-menu-vertical" active-text-color="#ff3636" router :default-active="$route.path">
      <el-menu-item v-for="(item, index) in menuList" :key="index" :index="item.link">
        <img :src="$route.path == item.link ? item.imgUrl2 : item.imgUrl1" alt />
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </el-menu> -->

    <ul class="menu">
      <li v-for="(item, index) in menuList" :key="index" @click="changeItem(index, item)"
        @mouseover="item.selected = true" @mouseout="item.selected = false" v-if="!netBarVIP || item.id !== 5">
        <a href="javascript:;" :class="(item.link == $route.path || $route.path.indexOf(item.link) > -1) ? 'active' : ''">
          <!-- <img v-if="(item.link == $route.path || $route.path.indexOf(item.link) > -1 || item.selected)"
            :src="item.imgUrl2" alt /> -->
          <img :src="($route.path == item.link || item.selected) ? item.imgUrl2 : item.imgUrl1" alt />
          <span>{{ item.name }}</span>
        </a>
      </li>
    </ul>

    <div class="playing" v-if="userInfo && userInfo.GameInfo" @click="goDetail(userInfo.GameInfo)">
      <div><img src="./../../assets/images/other/icon.png" />最近玩的</div>
      <!-- <p class="name">
        {{
          userInfo.GameInfo.gameInfoEntity &&
          userInfo.GameInfo.gameInfoEntity.Name
        }}
      </p>
      <el-button class="btn">登录游戏</el-button> -->
    </div>
    <!-- userInfo.GameInfo.ProductID -->

    <div class="btn-wrapper">
      <div class="smasher" @click="smasher" v-if="IsShowAccelerator">
        <img src="@/assets/images/home/ggzuhao.png" width="18px" height="18px" />
        加速器
      </div>

      <div class="feedback-box" @click="feedBack">
        <img class="img" src="@/assets/img/kf.png" width="25px" height="25px" />
        客服
      </div>

      <div class="feedback-box" @click="screenshot">
        <el-tooltip class="item" effect="dark" content="快捷键Ctrl+K，不隐藏特权窗口" placement="top">
          <div>
            <img class="img" src="@/assets/img/jt.png" width="21px" height="21px" />
            截图
          </div>
        </el-tooltip>
      </div>
    </div>

    <!-- 首次登录获得积分 -->
    <el-dialog :visible.sync="dialogVisibleFirstTime" width="417px" center custom-class="dialog-first-time"
      :before-close="closeFirstTimeDialog" :close-on-click-modal="false">
      <img src="@/assets/images/home/icon-20.png" alt />
      <span class="text">恭喜你！首次登录获得积分 +5</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeFirstTimeDialog"></el-button>
      </span>
    </el-dialog>

    <!-- 公众号登录弹窗 -->
    <el-dialog :visible.sync="dialogVisibleWeChat" width="496px" center @closed="closeDialog" custom-class="dialog-login"
      :close-on-click-modal="false">
      <template slot="title">
        <el-row :gutter="1">
          <el-col :span="24">
            <div class="login-type">
              <span>微信快速登录</span>
            </div>
          </el-col>
        </el-row>
      </template>
      <div class="login-box">
        <div class="Qr-code-box">
          <p class="ps">请使用微信扫描二维码登录</p>
          <div v-if="showImg" class="QRCode-box" style="border: 1px solid #ddd">
            <img src="@/assets/images/home/disable.png" alt />
          </div>
          <div v-else class="QRCode-box" v-loading="loading" element-loading-text="二维码生成中"
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)">
            <img v-if="QRCode" :src="QRCode" alt />
          </div>
        </div>
        <div class="prompt">
          <p>
            <img src="@/assets/img/login-icon1.png" />登录后你可以：<img src="@/assets/img/login-icon2.png" />付费游戏免费畅玩
          </p>
        </div>
      </div>
    </el-dialog>

    <!-- 手机号绑定弹窗 -->
    <el-dialog :visible.sync="dialogVisiblePhone" width="496px" center @closed="handleClose" custom-class="dialog-login"
      :close-on-click-modal="false">
      <template slot="title">
        <el-row :gutter="1">
          <el-col :span="24">
            <div class="login-type">
              <span>绑定手机号</span>
            </div>
          </el-col>
        </el-row>
      </template>
      <div class="login-box">
        <div class="login-form">
          <el-form ref="form" :model="form">
            <el-form-item>
              <el-input placeholder="请输入手机号码" v-model="form.phone" clearable>
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="请输入验证码" v-model="form.code" clearable>
                <template slot="append">
                  <el-button plain :disabled="isDisable" @click="_GetPhoneCode">
                    {{
                      timeSend >= 60
                      ? "获取验证码"
                      : "重新获取" + timeSend + "S"
                    }}
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="_BindPhone">确定</el-button>
            </el-form-item>
          </el-form>
          <p class="agreement" v-if="!(userInfo && userInfo.Phone)">
            登录成功即视为同意遵守
            <a href="javascript:;" @click="agreement">《注册及服务协议》</a>
          </p>
        </div>

        <div class="ps" v-if="userInfo && userInfo.Phone">
          <p>
            注意：换绑手机号后，账户积分将会清空，请谨慎换绑
          </p>
        </div>

        <div class="prompt" v-else>
          <p>
            <img src="@/assets/img/login-icon1.png" />登录后你可以：<img src="@/assets/img/login-icon2.png" />付费游戏免费畅玩
          </p>
        </div>
      </div>
    </el-dialog>

    <!-- 帮助与反馈 -->
    <HelpDialog />

    <!-- 实名认证 -->
    <Auth :updateVisible="isAuthShowDialog" @changeShow="showIsAuthVisible" />

    <!-- 登录成功广告弹窗 -->
    <el-dialog custom-class="ad-dialog" :visible.sync="adDialog" width="40%" center v-if="adObj"
      :close-on-click-modal="false">
      <!--  @closed="handleCloseAdDialog" -->
      <img :src="adObj.ADImageUrl" width="100%" @click.stop="adToLink(adObj.ADOpenUrl)" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {
  GetQRCode,
  AjaxIsAuthorize,
  GetPhoneCode,
  BindPhone,
  GetNetbarInfo,
  GetConsumerGameTimeByHaoYou,
} from "@/api";
import cMethods from "@/util/cMethods.js";
import HelpDialog from "./../components/HelpDialog";
import Auth from "./../components/Auth";
import { mapGetters } from "vuex";
// import { exportForCMethods } from "@/util/exportForCMethods.js"

export default {
  name: "VMenu",
  components: {
    HelpDialog,
    Auth,
  },
  // mixins: [exportForCMethods],
  computed: {
    ...mapGetters([
      "userInfo",
      "isLogin",
      "menuActive",
      "netBarId",
      "netBarName",
      "netBarVIP",
      "getIsAuth",
    ]),
  },
  data() {
    return {
      isAuthShowDialog: false,
      dialogVisiblePhone: false,
      dialogVisibleWeChat: false,
      toggle: false,
      menuList: [
        // {
        //   name: "首页",
        //   link: "/index",
        //   imgUrl1: require("@/assets/img/1.png"),
        //   imgUrl2: require("@/assets/img/1-1.png"),
        //   
        // },
        // {
        //   name: "免费玩",
        //   link: "/home",
        //   imgUrl1: require("@/assets/img/2.png"),
        //   imgUrl2: require("@/assets/img/2-1.png"),
        //   
        // },
        {
          id: 0,
          name: "用户中心",
          link: "/userCenter/history",
          imgUrl1: require("@/assets/img/1.png"),
          imgUrl2: require("@/assets/img/1-1.png"),
          selected: false,
        },
        {
          id: 1,
          name: "我的会员",
          link: "/members",
          imgUrl1: require("@/assets/img/6.png"),
          imgUrl2: require("@/assets/img/6-1.png"),
          selected: false,
        },
        {
          name: "积分商城",
          link: "/points",
          imgUrl1: require("@/assets/img/21.png"),
          imgUrl2: require("@/assets/img/21-1.png"),
          selected: false,
        },
        {
          id: 2,
          name: "完美存档",
          link: "/perfectArchiving",
          imgUrl1: require("@/assets/img/17.png"),
          imgUrl2: require("@/assets/img/17-1.png"),
          selected: false,
        },
        // {
        //   name: "我的存档",
        //   link: "/myItems",
        //   imgUrl1: require("@/assets/img/16.png"),
        //   imgUrl2: require("@/assets/img/16-1.png"),
        //   
        // },
        // {
        //   name: "历史游戏",
        //   link: "/history",
        //   imgUrl1: require("@/assets/img/3.png"),
        //   imgUrl2: require("@/assets/img/3-1.png"),
        //   
        // },
        // {
        //   name: "我的收藏",
        //   link: "/myCollection",
        //   imgUrl1: require("@/assets/img/15.png"),
        //   imgUrl2: require("@/assets/img/15-1.png"),
        //   
        // },
        {
          id: 3,
          name: "游戏菜单",
          link: "/menu",
          imgUrl1: require("@/assets/img/18.png"),
          imgUrl2: require("@/assets/img/18-1.png"),
          selected: false,
        },
        {
          id: 4,
          name: "买号玩",
          link: "/link",
          imgUrl1: require("@/assets/img/4.png"),
          imgUrl2: require("@/assets/img/4-1.png"),
          selected: false,
        },
        // {
        //   id: 5,
        //   name: "账号回收",
        //   //link: "http://www.taohaobang.com/activity/accountRecovery?from=mydjClient",
        //   link: "http://w.taohaobang.com/activity/accountRecovery?from=mydjClient",
        //   imgUrl1: require("@/assets/img/5.png"),
        //   imgUrl2: require("@/assets/img/5-1.png"),
        //   selected: false,
        // },
        // {
        //   name: "加速服务",
        //   link: "/steam",
        //   imgUrl1: require("@/assets/img/19.png"),
        //   imgUrl2: require("@/assets/img/19-1.png"),
        //   
        // },
        {
          id: 5,
          name: "周免游戏",
          //link: "http://www.taohaobang.com/activity/accountRecovery?from=mydjClient",
          link: "/weeklyFreeGame",
          imgUrl1: require("@/assets/img/16.png"),
          imgUrl2: require("@/assets/img/16-1.png"),
          selected: false,
        },
      ],
      visible: false,
      dialogVisibleFirstTime: false,
      form: {
        phone: "",
        code: "",
      },
      loading: true,
      QRCode: "",
      Code: "",
      timer: null,
      timeSend: 60,
      isDisable: false,
      countdown: null,
      IsShowAccelerator: false, //是否显示加速器
      showImg: false,
      adDialog: false,
      adObj: null
    };
  },
  mounted() {
    // if (this.netBarId) {
    //   this._GetNetbarInfo();
    // }

    eventBus.$on("getQrCode", (toggle) => {
      this.QRCode = "";
      this.dialogVisibleWeChat = true;
      // this.$store.commit("SET_DIALOGVISIBLEWECHAT", true);
      // cMethods.Extern_Form_WriteHtmlLog("准备获取二维码...");
      this._GetQRCode();

      // 统计登录数据
      if (this.netBarVIP) {
        this.$statistic(["A", "钻石版-登录窗口", "二维码", "展现量"]);
      } else {
        this.$statistic(["A", "黄金版-登录窗口", "二维码", "展现量"]);
      }
    });

    eventBus.$on("bindPhone", (toggle) => {
      this.changePhone()
    })

    eventBus.$on("showAuthVisible", (toggle) => {
      this.showIsAuthVisible()
    })

    eventBus.$on("showLoginPhone", (toggle) => {
      this.dialogVisiblePhone = true;
    });

    eventBus.$on("showAuth", (toggle) => {
      this.isAuthShowDialog = true;
    });

    this.$store.dispatch("_getIsNotVerificationIDCard");
  },
  methods: {
    changePhone() {
      if (this.userInfo && this.userInfo.State == 2) {
        this.$message({
          message: "您存在严重违规行为，禁止绑定",
          type: "error",
        });
        return false;
      }
      this.dialogVisiblePhone = true;
    },
    showIsAuthVisible(data) {
      if (data === "false") {
        this.isAuthShowDialog = false;
      } else {
        this.isAuthShowDialog = true;
      }
    },
    // 去反馈
    feedBack() {
      this.$statistic(["B", "左边菜单", "点击", "客服帮助"]);
      // eventBus.$emit("showHelpDialog", true);
      // this.$statistic(["B", "客服帮助", "点击", "联系在线客服"]);
      cMethods.OpenIeUrl(
        "http://www.mayidianjing.com/Content/customerService/xiaoneng.html"
      );
    },
    // 登录游戏
    goDetail(info) {
      // if (info.GameID !== "GM002276") {
      //   this.$router.push("/gameDetail/" + info.ProductID);
      // } else {
      //   this.$router.push("/boutique");
      // }
      this.$router.push("/userCenter/history");
    },
    // 获取网吧信息
    async _GetNetbarInfo() {
      let params = {
        netbarId: this.netBarId,
      };
      let res = await GetNetbarInfo(params);
      if (res.Object) {
        this.IsShowAccelerator = res.Object.NetbarBusiness.IsNotShowAccelerator;
        if (
          res.Object.ProductPowerInfos[0] &&
          res.Object.ProductPowerInfos[0].ExpireDateTime &&
          res.Object.ProductPowerInfos[0].IsExpire == "已到期"
        ) {
          this.$notify({
            title: "温馨提示",
            message:
              "钻石版已过期，建议联系网管开通钻石版（钻石版特权游戏免费玩）",
            duration: 0,
            type: "error",
            position: "bottom-right",
          });
        }
      }
    },
    // 切换左侧菜单
    changeItem(i, item) {
      if (item.link.indexOf("http") != -1) {
        cMethods.OpenIeUrl(item.link);
      } else {
        if (item.link == "/points") {
          eventBus.$emit("reloadIframe")
        }
        if (
          // item.link == "/history" ||
          // item.link == "/myCollection" ||
          // item.link == "/myItems"
          item.link == "/userCenter/history"
          //  || item.link == "/points"
        ) {
          //item.link == "/link" ||
          if (!this.isLogin) {
            eventBus.$emit("getQrCode");
          } else {
            if (sessionStorage.getItem("Phone")) {
              this.$router.push(item.link);
            } else {
              this.$router.push("/");
              eventBus.$emit("showLoginPhone");
            }
          }
        } else {
          this.$router.push(item.link);
        }
      }
      if (item.link == "/link") {
        this.$statistic(["B", "蚂蚁菜单", "点击", "买号玩"]);
      }

      if (item.link == "/menu") {
        this.$statistic(["B", "游戏菜单", "点击", "按钮点击量"]);
      }
    },
    // 手机号码、短信登录
    async _BindPhone() {
      if (this.checkPhone()) {
        if (!this.form.code) {
          //未输入手机验证码
          this.$message({
            message: "请输入手机验证码",
            type: "warning",
          });
          return false;
        }
        if (this.userInfo) {
          if (this.userInfo.Phone == this.form.phone) {
            this.$message({
              message: "您已绑定此手机号码",
              type: "success",
            });
            return false;
          }

          let params = {
            phone: this.form.phone,
            phoneCode: this.form.code,
            consumerId: this.userInfo.ConsumerId,
          };

          let res = await BindPhone(params);
          if (res.StatusCode == 200) {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
            // console.log("res..", res);
            // 修改store登录状态
            sessionStorage.setItem("Phone", res.Message);
            this.$store.dispatch("_GetUserInfo");

            // this.$store.commit("SET_USERINFO", res.Object);
            // this.$store.commit("SET_ISLOGIN", true);
            // this.$store.commit("SET_DIALOGVISIBLEWECHAT", false);
            this.handleClose();
            // // cMethods.SetUserID(res.Object.ConsumerId);
            this.$router.push("/");
          }
        }
      }
    },
    // 获取短信验证码
    async _GetPhoneCode() {
      if (this.checkPhone()) {
        if (this.userInfo) {
          if (this.userInfo.Phone == this.form.phone) {
            this.$message({
              message: "您已绑定此手机号码",
              type: "success",
            });
            return false;
          }
        }

        if (this.timeSend == 60) {
          this.isDisable = true;
          let params = {
            phone: this.form.phone,
          };

          let res = await GetPhoneCode(params);
          if (res.StatusCode == 200 && res.Object) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
            let _this = this;
            clearInterval(_this.countdown);
            _this.countdown = null;
            _this.countdown = setInterval(() => {
              _this.timeSend--;
              if (_this.timeSend == 0) {
                clearInterval(_this.countdown);
                _this.countdown = null;
                _this.isDisable = false;
                _this.timeSend = 60;
              }
            }, 1000);
          } else {
            this.$message({
              message: res.Message,
              type: "error",
            });
            this.timeSend = 60;
            this.isDisable = false;
          }
        }
      }
    },
    checkPhone() {
      if (!this.form.phone) {
        //未输入手机号
        this.$message({
          message: "请输入手机号码",
          type: "warning",
        });
        return false;
      }
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.form.phone)) {
        //手机号不合法
        this.$message({
          message: "您输入的手机号码不合法，请重新输入",
          type: "error",
        });
        return false;
      }
      return true;
    },
    // 获取微信二维码
    async _GetQRCode() {
      if (!this.netBarId) {
        this.$message({
          message: "获取网吧ID错误",
          type: "error",
        });
      } else {
        let params = {
          random: this.netBarId,
        };
        // cMethods.Extern_Form_WriteHtmlLog(`网吧ID => ${this.netBarId}, 请求获取二维码接口`);
        let res = await GetQRCode(params);
        // cMethods.Extern_Form_WriteHtmlLog(`获取二维码接口返回 => ${JSON.stringify(res)}`);
        if (res.StatusCode == 200) {
          if (res.Object) {
            if (res.Object.Code && res.Object.Code !== "false") {
              this.Code = res.Object.Code;
              this.QRCode = res.Object.Base64;
              this.loading = false;
              clearInterval(this.timer)
              this.timer = setInterval(this._AjaxIsAuthorize, 3000);
            } else {
              this.showImg = true;
              // 当前IP未授权
              this.$statistic([
                "A",
                `真实IP与网吧不符${this.netBarId}`,
                "微信登录",
                "展现量",
              ]);
            }
          }
          this.$statistic(["A", "登陆界面", "窗口", "访问量"]);
        } else {
          this.closeDialog();
          this.$message({
            message: res.Message,
            type: "error",
          });

          // 获取机器码
          let Machinecode = cMethods.FormGetMachineCode();
          this.$statistic(["A", "没有获取网吧Key：【" + Machinecode + "】", "提示框", "展现量"]);
        }
      }
    },
    // 获取微信登录验证
    async _AjaxIsAuthorize() {
      let params = {
        code: this.Code,
      };
      let res = await AjaxIsAuthorize(params);
      if (res.StatusCode == 200) {
        if (res.Object) {
          if (res.Object.State == 2) {
            this.closeDialog();
            // 封禁用户
            this.$message({
              message: "您存在严重违规行为，禁止登录",
              type: "error",
            });
            return false;
          }
          // 修改store登录状态
          if (res.Object.Phone) {
            sessionStorage.setItem("Phone", res.Object.Phone);
            this._GetConsumerGameTimeByHaoYou(res.Object.Phone);
          }
          this.$store.commit("SET_USERINFO", res.Object);
          this.$store.commit("SET_ISLOGIN", true);

          cMethods.SetUserID(res.Object.ConsumerId);

          // 刷新用户信息
          this.$store.dispatch("_GetUserInfo").then(() => {
            // window.location.reload();
            this.$nextTick(function () {
              // 刷新页面
              this.$router.replace({ path: "/refresh" });
            });
          });

          if (sessionStorage.getItem('adList')) {
            let adArr = JSON.parse(sessionStorage.getItem('adList'))
            if (adArr) {
              this.adObj = adArr.filter(item => item.ADName === '登录成功广告')[0]
              this.adDialog = true;
            }
          }

          this.closeDialog();

          // 统计登录数据
          if (this.netBarVIP) {
            this.$statistic(["A", "钻石版-登录成功", "窗口", "展现量"]);
          } else {
            this.$statistic(["A", "黄金版-登录成功", "窗口", "展现量"]);
          }
        }
      } else {
        this.closeDialog();
        // this._GetQRCode();
      }
    },
    // 获取在线游戏时长
    async _GetConsumerGameTimeByHaoYou(phone) {
      let params = {
        phone: phone,
      };
      let res = await GetConsumerGameTimeByHaoYou(params);
      if (res.StatusCode == 200) {
        let timer = parseFloat(res.Object / 60).toFixed(1);
        this.$store.commit("setTimer", timer);
      }
    },
    // 请登录
    // pleaseLogin() {
    //   if (!this.isLogin) {
    //     this.QRCode = "";
    //     eventBus.$emit("getQrCode");
    //   }
    // },
    // 关闭首次登录弹窗
    closeFirstTimeDialog() {
      this.dialogVisibleFirstTime = false;
      sessionStorage.setItem("FirstTimeDialog", true);
    },
    closeDialog() {
      console.log("关闭登录...");
      clearInterval(this.timer);
      this.timer = null;

      // 关闭二维码窗口
      this.dialogVisibleWeChat = false;
    },
    // 关闭登录弹窗
    handleClose() {
      // 重置绑定手机号表单
      this.form.phone = "";
      this.form.code = "";
      clearInterval(this.countdown);
      this.countdown = null;
      this.isDisable = false;
      this.timeSend = 60;

      // 关闭绑定手机号窗口
      this.dialogVisiblePhone = false;
    },
    // 请登录
    pleaseLogin() {
      if (!this.isLogin) {
        this.QRCode = "";
        eventBus.$emit("getQrCode");
      }
    },
    // 点击服务协议
    agreement() {
      this.$statistic(["B", "用户登录", "点击", "服务协议"]);
      cMethods.OpenIeUrl(
        "http://www.mayidianjing.com/Content/html/Agreement.html"
      );
    },
    // 加速器
    smasher() {
      cMethods.ExternFormBoost();
    },
    // 积分立即前往
    leaveFor(i) {
      this.$router.push("/integralmall/" + i);
    },
    // // 关闭登录成功弹窗全局刷新
    // handleCloseAdDialog() {
    //   // window.location.reload();
    // },
    // 登录成功弹窗跳转
    adToLink(url) {
      this.adDialog = false;
      this.$router.push(url)
    },
    // 截图
    screenshot() {
      let res = cMethods.Form_jsCaptureScreenAndCopyToClipboard();
      if (res) {
        this.$message({
          message: "截图已复制到剪贴板(按Ctrl+V粘贴图片)",
          type: "success",
          duration: 5000,
          showClose: true,
        });
      }
      this.$statistic(["B", "左侧菜单", "截图", "点击量"]);
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
  height: 100%;
  position: relative;

  // @include header_bg_color();
  // 菜单展开/收起样式
  .avatar-box {
    padding: 20px 0;
    text-align: center;

    .header-box {
      width: 60px;
      border: 5px solid #f3f3f3;
      border-radius: 35px;
      margin: 0 auto;
      position: relative;

      .auth-icon {
        position: absolute;
        left: 0px;
        bottom: -5px;
        cursor: pointer;
      }
    }

    .user-name {
      padding: 0 8px;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        @include font_col6();
      }
    }

    .user-phone {
      display: flex;
      justify-content: center;
      align-items: center;

      line-height: 30px;

      a {
        margin-right: 3px;
        font-size: 12px;
      }

      .el-button {
        font-size: 12px;
        padding: 5px;
        background: linear-gradient(90deg, #ff9a68 0%, #ff6868 100%);
        border-radius: 4px;
        color: #fff;
        border: none;
      }
    }

    .integral {
      margin-top: 5px;
      color: $red-col;

      a {
        @include font_col6();
      }
    }
  }

  .avatar-box {
    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      cursor: pointer;
    }

    .user-name {
      font-size: 16px;
    }
  }

  // 菜单展开/收起样式
  .menu {
    // height: 330px;
    display: flex;
    flex-direction: column;

    li {
      float: left;
      width: 100%;
      margin-bottom: 2px;
      font-size: 15px;

      a {
        height: 52px;
        line-height: 52px;
        display: block;
        @include font_col3();

        img {
          margin-left: 14px;
          margin-right: 8px;
          margin-bottom: 4px;
        }
      }
    }
  }

  .menu {
    li {
      .active {
        width: 120px;
        background: url("./../../assets/img/menu-active.png") no-repeat;
        background-size: 100% 100%;
        z-index: 2000;
        color: $red-col !important;

        img {
          margin-left: 20px;
        }
      }
    }

    li:hover a {
      width: 120px;
      background: url("./../../assets/img/menu-active.png") no-repeat;
      background-size: 100% 100%;
      z-index: 2000;
      color: $red-col;

      img {
        margin-left: 20px;
      }
    }
  }

  .playing {
    margin-top: 5px;
    padding: 10px;
    @include font_col3();
    cursor: pointer;

    img {
      margin-right: 5px;
      margin-top: -3px;
    }

    .name {
      margin: 8px 0;
      @include font_col9();
    }

    .btn {
      width: 66px;
      height: 24px;
      color: $white-col;
      padding: 0;
      margin-left: 35px;
      font-size: 12px;
      border: none;
      background: $red-col;
    }
  }

  .btn-wrapper {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    position: absolute;
    bottom: 20px;
    left: 0;

    .smasher,
    .feedback-box {
      width: 100px;
      height: 32px;
      border-radius: 4px;
      border: none;
      color: $white-col;
      cursor: pointer;
      margin-bottom: 6px;
      background: $red-col;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        margin-right: 8px;
      }
    }

    // .screenshot {
    //   width: 100%;
    //   font-size: 14px;
    //   border-radius: 4px;
    // }
  }

  .dialog-login {
    .login-type {
      font-size: 18px;
      cursor: pointer;
      position: relative;
    }

    .login-box {
      .Qr-code-box {
        height: 300px;
        text-align: center;

        .ps {
          font-size: 16px;
          margin-bottom: 24px;
          @include font_col6();
        }

        .QRCode-box {
          width: 224px;
          height: 224px;
          text-align: center;
          padding: 10px;
          background: $white-col;
          // border: 1px solid $border-col;
          // border: 1px solid rgba(155, 155, 156, 0.3);
          @include item_bd_color();
          margin: 0 auto 40px;

          img {
            width: 224px;
            height: 224px;
          }
        }
      }

      .login-form {
        height: 284px;
        padding-top: 16px;
        text-align: center;

        .el-form {
          width: 378px;
          margin: 0 auto;

          ::v-deep .el-form-item {
            margin-bottom: 28px;
          }

          ::v-deep .el-input__inner {
            border-radius: 0;
            height: 50px;
            line-height: 50px;
          }

          ::v-deep .el-input-group__prepend {
            border-radius: 0;
          }

          ::v-deep .el-input-group__append {
            border-radius: 0;
            background: #194e73;
            padding: 0;
            border: none;

            .el-button {
              width: 168px;
              height: 50px;
              line-height: 50px;
              display: block;
              color: $white-col;
              padding: 0;
              margin: 0;
            }
          }

          ::v-deep .el-button {
            width: 378px;
            height: 50px;
            color: $white-col;
            border-radius: 0;
            margin-top: 16px;
            background: $red-col;
          }
        }

        .ps {
          font-size: 16px;
          color: #0096ff;
          margin-bottom: 24px;
        }

        .agreement {
          line-height: 30px;

          a {
            color: #0096ff;
          }
        }
      }

      .prompt {
        line-height: 30px;
        text-align: center;
        @include font_col9();

        p {
          img {
            margin-right: 5px;
          }
        }
      }

      .ps {
        line-height: 30px;
        text-align: center;
        color: $red_col;
      }
    }
  }
}
</style>

<style lang="scss">
.dialog-first-time {
  background: none !important;

  .el-dialog__body {
    position: relative;

    .text {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 26px;
      color: $white-col;
      font-size: 20px;
      text-align: center;
    }
  }

  .el-dialog__footer {
    .el-button {
      width: 156px;
      height: 54px;
      background: url("./../../assets/images/home/icon-11.png");
      border: none;
    }
  }
}

.el-notification {
  @include item_bd_color();
  @include item_bg_col();

  .el-notification__title {
    @include font_col3();
  }

  .el-notification__content {
    @include font_col6();
  }
}

.ad-dialog {
  box-shadow: none !important;
  background: transparent !important;
  cursor: pointer;

  .el-dialog__header {
    padding: 0;
    border-bottom: none;
  }

  .el-dialog__headerbtn {
    top: -30px !important;
    right: -30px !important;

    .el-dialog__close {
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
      border: 1px solid #fff;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }


  .el-dialog__body {
    padding: 0 !important;
    background: transparent;
  }
}
</style>