<template>
  <div>
    <el-dialog
      title="温馨提示"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
      custom-class="blackListDialog"
      :close-on-click-modal="false"
    >
      <span class="text-content">{{ errorMessage }}</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="onLineService">联系在线客服</el-button> -->
        <el-button type="primary" @click="apply">提交解封申请</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="解封申请"
      :visible.sync="applyDialogVisible"
      width="500px"
      center
      custom-class="applyListDialog"
      :close-on-click-modal="false"
      v-if="userInfo"
    >
      <p>
        当前网吧名称:<span>{{ netBarName }}</span>
      </p>
      <p>
        当前网吧id:<span>{{ netBarId }}</span>
      </p>
      <p>
        当前被封用户id:<span>{{ userInfo.ConsumerId }}</span>
      </p>
      <p>
        当前被封用户手机号:<span>{{ userInfo.Phone }}</span>
      </p>
      <p class="ps">
        *注意：只有账号存在误封的情况下才可能解封，解封成功后，会通过公众号消息推送给您，若解封不通过
        则再次登录蚂蚁特权账号可查看解封申请不通过原因
        <br />解封人员工作时间：工作日（周一到周五）上午9点-下午18点
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleApply">申请解封</el-button>

        <div class="ps" v-if="showTime">
          当前为非解封工作人员上班时间，预计工作时间1小时内处理完成
        </div>
        <div class="ps" v-else>现在提交预计1小时内处理完成</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import cMethods from "@/util/cMethods.js";
import { mapGetters } from "vuex";
import { ProposalAdd } from "@/api";

export default {
  name: "BlackListDialog",
  props: ["errorMessage"],
  computed: {
    ...mapGetters(["userInfo", "netBarId", "netBarName"]),
  },
  data() {
    return {
      centerDialogVisible: false,
      applyDialogVisible: false,
      showTime: false,
    };
  },
  methods: {
    blackListDialogShow() {
      this.centerDialogVisible = true;
    },
    blackListDialogHide() {
      this.centerDialogVisible = false;
    },
    // 提交申请解封
    async handleApply() {
      let params = {
        NetbarId: this.netBarId,
        NetbarName: this.netBarName,
        ConsumerId: this.userInfo.ConsumerId,
        Type: "申请解封",
        Content: "申请解封",
        OrderNumber: "",
        ValidateKey: "",
        Code: "",
      };
      let res = await ProposalAdd(params);
      if (res.StatusCode == 200) {
        this.$message({
          message: "提交成功",
          type: "success",
        });
      }
      // if (res.data == "Error: Request failed with status code 400") {
      //   this.$message({
      //     message: res.data.response.data.Message,
      //     type: "error",
      //   });
      // }
      this.applyDialogVisible = false;
    },
    // 解封弹窗
    apply() {
      this.blackListDialogHide();
      this.getNowFormatDate();
      this.applyDialogVisible = true;
    },
    // 在线人工客服
    onLineService() {
      this.centerDialogVisible = false;
      cMethods.OpenIeUrl(
        "http://www.mayidianjing.com/Content/customerService/xiaoneng.html"
      );
      this.$statistic(["B", "客服帮助", "点击", "联系在线客服"]);
    },
    // 判断日期是不是周末
    getNowFormatDate() {
      let date = new Date().getDay();
      let time = new Date().getHours();
      // return date == 0 || date == 6 ? true : false;
      if (date == 0 || date == 6) {
        if (time > 9 && time < 18) {
          this.showTime = true;
        } else {
          this.showTime = false;
        }
      } else {
        this.showTime = false;
      }
    },
  },
};
</script>

<style lang="scss">
.blackListDialog,
.applyListDialog {
  .el-button--default:focus,
  .el-button--default:hover {
    color: #ff3636;
    border-color: #ff3636;
    background-color: transparent;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #ff3636 !important;
  }
  ::v-deep .el-dialog__body {
    .text-content {
      font-size: 18px;
      @include font_col3();
    }
  }
}
.applyListDialog {
  p {
    margin-bottom: 10px;
    line-height: 20px;
    span {
      margin-left: 10px;
    }
    &.ps {
      color: red;
    }
  }
  .dialog-footer {
    .ps {
      margin-top: 10px;
    }
  }
}
</style>